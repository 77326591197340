<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-button
          icon="RefreshRight"
          size="small"
          style="margin-right: 5px"
          @click="getList()"
        ></el-button>
        <el-input
          size="small"
          placeholder="请输入客户编码/客户名称"
          v-model.trim="listQuery.customerName"
          style="width: 200px"
          @keyup.enter="getList"
          clearable
        ></el-input>
        <el-button
          size="small"
          type="primary"
          @click="getList"
          style="margin-right: 10px"
          icon="Search"
          >搜索</el-button
        >
        <search @success="getList" @cancel="cancel">
          <el-form
            style="width: 350px"
            label-width="90px"
            size="small"
            class="style_form"
          >
            <el-form-item label="停用日期：">
              <el-date-picker
                style="width: 100px"
                v-model="listQuery.stopStartDate"
                type="date"
                placeholder="选择日期"
                clearable
                value-format="YYYY-MM-DD"
              />
              &nbsp;-&nbsp;
              <el-date-picker
                style="width: 100px"
                v-model="listQuery.stopEndDate"
                type="date"
                placeholder="选择日期"
                clearable
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
            <el-form-item label="主办会计：">
              <select-common
                placeholder="请选择主办会计"
                v-model:modelValue="listQuery.accountingId"
                :options="userOptions"
                valueKey="id"
                labelKey="CnName"
              />
            </el-form-item>
            <el-form-item label="停用原因：">
              <el-input v-model="listQuery.reasonLoss"></el-input>
            </el-form-item>
            <el-form-item label="停用人：">
              <select-common
                placeholder="请选择停用人"
                v-model:modelValue="listQuery.stopName"
                :options="userOptions"
                valueKey="CnName"
                labelKey="CnName"
              />
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div>
        <el-button size="small" type="success" @click="restart" icon="Refresh"
          >启用</el-button
        >
      </div>
    </div>
    <div :style="{ height: contentStyleObj, paddingTop: '10px' }">
      <vxe-table
        v-loading="loading"
        :data="list"
        :scroll-y="{ enabled: true, gt: 0 }"
        height="auto"
        style="width: 100%"
        border
        stripe
        auto-resize
        size="mini"
        @checkbox-all="handleSelectionChangeAll"
        @checkbox-change="handleSelectionChange"
        :column-config="{ resizable: true }"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <vxe-column
          align="center"
          field="sortColumn"
          type="checkbox"
          width="50"
        >
        </vxe-column>
        <vxe-column field="customerNum" title="客户编码" width="80">
          <template #default="scope">
            <span>{{ scope.row.customerNum }}</span>
          </template>
        </vxe-column>
        <vxe-column field="customerName" title="客户名称">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <div class="name_left">
                <span>{{ scope.row.customerName }}</span>
              </div>
              <el-tooltip content="复制" placement="top" effect="dark">
                <i
                  class="iconfont icon-fuzhi1"
                  style="color: var(--themeColor, #17a2b8); cursor: pointer"
                  @click="$copyComName(scope.row.customerName)"
                >
                </i>
              </el-tooltip>
            </div>
          </template>
        </vxe-column>
        <vxe-column field="hybz" title="国家标准行业"> </vxe-column>
        <vxe-column field="accountSystem" title="会计制度"> </vxe-column>
        <vxe-column field="manager" title="法定代表人"> </vxe-column>
        <vxe-column field="reasonLoss" title="流失原因"> </vxe-column>
        <vxe-column field="stopDate" title="停用时间">
          <template #default="scope">
            <span>{{ $parseTime(scope.row.stopDate, "{y}-{m}-{d}") }}</span>
          </template>
        </vxe-column>
        <vxe-column field="stopName" title="停用人"> </vxe-column>
      </vxe-table>
    </div>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { customerList, totalStopCustomer } from "@/api/newCrm";
import { findPeople } from "@/api/base";
const { proxy } = getCurrentInstance();
const list = ref([]);
const total = ref(0);
const contentStyleObj = ref({});
const loading = ref(false);
const listQuery = ref({
  page: 1,
  limit: 20,
  customerType: "3",
});
const sels = ref([]);
const userOptions = ref([]);
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(210);
  findPeople({ code: "bookkeeping" }).then((res) => {
    userOptions.value = res.data.data.list;
  });
  getList();
});
const getList = () => {
  loading.value = true;
  customerList(listQuery.value).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list ? res.data.data.list : [];
      total.value = res.data.data.total;
    }
  });
};

const handleSelectionChange = (data) => {
  sels.value = data.records;
};
const handleSelectionChangeAll = (data) => {
  sels.value = data.records;
};
//启用
const restart = () => {
  if (sels.value.length == 0) {
    ElMessage.warning("请选择客户");
    return;
  }
  totalStopCustomer({
    ids: sels.value.map((v) => v.id),
    customerType: "2",
  }).then((res) => {
    if (res.data.msg == "success") {
      ElMessage.success("启用成功");
      getList();
    }
  });
};
const cancel = () => {
  let originLimit = listQuery.value.limit;
  listQuery.value = {
    page: 1,
    limit: originLimit,
    userId: [],
    customerType: "3",
  };
};
</script>
<script>
export default {
  name: "clientRun",
};
</script>
<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
:deep(.el-checkbox) {
  margin-right: 15px;
}
.style_form {
  .el-input {
    width: 211px;
  }
  .el-select {
    width: 211px;
    margin: 0;
  }
}
.filter-item {
  margin-right: 15px !important;
}
:deep(.el-dropdown-menu__item) {
  display: block;
}
.name_left {
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
